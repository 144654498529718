import { useEffect, useCallback } from 'react';

import type { ProgressBarContainer } from '@confluence/global-progress-bar-container';

import { START_MESSAGE_TYPE, FINISH_MESSAGE_TYPE } from './messageTypes';

export const useProgressBarListenerController = (progressBar: ProgressBarContainer) => {
	const { start, finish } = progressBar;

	const postMessageHandler = useCallback(
		(event: any) => {
			if (event.data && event.data.eventType == START_MESSAGE_TYPE) {
				void start(event.data.startDuration, event.data.delayDuration);
			}
			if (event.data && event.data.eventType == FINISH_MESSAGE_TYPE) {
				void finish();
			}
		},
		[start, finish],
	);

	useEffect(() => {
		window.addEventListener('message', postMessageHandler);
		return () => {
			window.removeEventListener('message', postMessageHandler);
		};
	}, [postMessageHandler]);
};
