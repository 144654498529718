import type { FC } from 'react';
import React, { memo } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import type { NavigationTheme } from '@atlaskit/atlassian-navigation';

import { ProgressBarContainer } from '@confluence/global-progress-bar-container';

import { WrappedProgressBar } from './WrappedProgressBar';

type ProgressBarProps = {
	theme?: NavigationTheme;
	isNav4?: boolean;
};

export const ProgressBar: FC<ProgressBarProps> = memo(({ theme, isNav4 }) => {
	const highlightColor = theme && theme.mode.productHome.backgroundColor;

	return (
		<Subscribe to={[ProgressBarContainer]}>
			{(progressBar: ProgressBarContainer) => (
				<WrappedProgressBar
					highlightColor={highlightColor}
					status={progressBar.getStatus()}
					progressBar={progressBar}
					duration={progressBar.getDuration()}
					isNav4={isNav4}
				/>
			)}
		</Subscribe>
	);
});
