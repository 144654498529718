/* eslint react/no-danger: 0 */
/* eslint @atlaskit/ui-styling-standard/no-global-styles: 0 */
import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import type { ProgressBarContainer } from '@confluence/global-progress-bar-container';
import {
	PROGRESS_STYLE_ID,
	DEFAULT_LOADING_BAR_COLOR,
	EXIT_ANIMATION_SPEED,
	PROGRESS_BAR_ID,
} from '@confluence/global-progress-bar-container';

import { useProgressBarController } from './useProgressBarController';
import { useProgressBarListenerController } from './useProgressBarListenerController';

type WrappedProgressBarProps = {
	highlightColor?: string;
	status: string;
	progressBar: ProgressBarContainer;
	duration: number;
	isNav4?: boolean;
};

type StyledAttributes = {
	scaleX?: number;
	transitionDuration?: number;
	isDelayed?: boolean;
	animation?: string;
	ssrProgressBarLoadPercent?: number;
};

const getStyledAttributes = (
	status,
	duration,
	ssrProgressBarDuration,
	ssrProgressBarLoadPercent,
) => {
	switch (status) {
		case 'START':
			return {
				scaleX: 0.99,
				transitionDuration: duration,
			};

		case 'FINISH':
			return {
				scaleX: 1,
				transitionDuration: EXIT_ANIMATION_SPEED,
			};

		case 'DELAYED':
			return {
				animation: 'shimmer 1200ms infinite',
				isDelayed: true,
				scaleX: 1,
				transitionDuration: 0,
			};

		case 'SSR':
			// When browser is rendering the static HTML progress from 0 to 1/3
			// Then when SPA loads proceed from 33.33% to 99%, then 100% when apdex ends
			return {
				animation: process.env.REACT_SSR ? `ssr ${ssrProgressBarDuration}ms ease-out` : 'none',
				scaleX: ssrProgressBarLoadPercent / 100,
				transitionDuration: 0,
			};

		case 'RESET':
		default:
			return {
				animation: 'none',
				scaleX: 0,
				transitionDuration: 0,
			};
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const StyledProgressBar = styled.div<
	StyledAttributes & {
		background: string;
	}
>`
	animation: ${({ animation = 'none' }) => animation};
	background: ${({ background = 'none' }) => background};
	height: 2px;
	opacity: 0.6;
	position: absolute;
	transform: scaleX(${({ scaleX }) => scaleX});
	transform-origin: 0 0;
	transition-duration: ${({ transitionDuration }) => transitionDuration}ms;
	width: 100%;
	will-change: transform;
	z-index: 999;

	${() =>
		process.env.REACT_SSR ? `@keyframes ssr { from { width: 0%; } to { width:100%; } }` : ''}

	${({ isDelayed }) =>
		isDelayed
			? ` @keyframes shimmer { from { left: -15%; width: 15%; } to { left: 115%; width: 15%; } }`
			: ''}
`;

//start Nav 4 styles
const generateLoadingBarStyles = ({
	animation = 'none',
	background,
	isDelayed,
	scaleX,
	transitionDuration,
}: StyledAttributes & {
	background: string;
}): string => {
	return `
	body::before{
    content: '';
    display: block;
    animation: ${animation};
	  background: ${
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
			background
		};
    height: 2px;
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
	  transform: scaleX(${scaleX});
		transition-duration: ${transitionDuration}ms;
    transform-origin: 0 0;
    will-change: transform;
  }
  ${process.env.REACT_SSR ? `@keyframes ssr { from { width: 0%; } to { width:100%; } }` : ''}
	${
		isDelayed
			? ` @keyframes shimmer { from { left: -15%; width: 15%; } to { left: 115%; width: 15%; } }`
			: ''
	}
`;
};
//end Nav 4 specific code

export const WrappedProgressBar: FC<WrappedProgressBarProps> = ({
	highlightColor = DEFAULT_LOADING_BAR_COLOR,
	status,
	duration,
	progressBar,
	isNav4,
}) => {
	const ssrAttributes = getStyledAttributes(
		'SSR',
		0,
		6000, // ssrProgressBarDuration
		80, // ssrProgressBarLoadPercent
	);

	// Initially, set progress bar to 0%, this ensures that progress bar does not start with 99% on the first load
	const initialAttributes = getStyledAttributes(
		'RESET',
		duration,
		6000, // ssrProgressBarDuration,
		80, // ssrProgressBarLoadPercent
	);

	const [attributes, setAttributes] = useState<StyledAttributes>(
		// If the page is currently rendering on the server-side or was rendered on server-side
		// We need to set the start point synchronously
		status === 'SSR' ? ssrAttributes : initialAttributes,
	);

	useProgressBarController(progressBar);
	useProgressBarListenerController(progressBar);
	/**
	 * this logic (useEffect -> setTimeout -> setState) is necessary to fix a
	 * rendering issue where the progress bar animation starts loading at 99%.
	 * this should be investigated more and solved in a future ticket.
	 */
	useEffect(() => {
		const timeout = setTimeout(() => {
			setAttributes(
				getStyledAttributes(
					status,
					duration,
					6000, // ssrProgressBarDuration,
					80, // ssrProgressBarLoadPercent
				),
			);
		}, 0);

		return () => clearTimeout(timeout);
	}, [duration, status]);

	const dynamicLoadingStyles = generateLoadingBarStyles({
		...attributes,
		background: highlightColor,
	});

	return isNav4 ? (
		<style
			data-testId={PROGRESS_STYLE_ID}
			dangerouslySetInnerHTML={{ __html: dynamicLoadingStyles }}
		/>
	) : (
		<StyledProgressBar data-testid={PROGRESS_BAR_ID} background={highlightColor} {...attributes} />
	);
};
